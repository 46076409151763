function debounce(fn, threshold) {
    var timeout;
    return function debounced() {
        if ( timeout ) {
            clearTimeout( timeout );
        }
        function delayed() {
            fn();
            timeout = null;
        }
        timeout = setTimeout( delayed, threshold || 100 );
    };
}

function throttle(fn, threshhold, scope) {
    threshhold = ('undefined' !== typeof threshhold) ? threshhold :  250;
    var last,
        deferTimer;
    return function () {
        var context = scope || this;

        var now = +new Date(),
            args = arguments;
        if (last && now < last + threshhold) {
            // hold on to it
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function () {
                last = now;
                fn.apply(context, args);
            }, threshhold);
        } else {
            last = now;
            fn.apply(context, args);
        }
    };
}

var scale = function(opts) {
    var istart = opts.domain[0],
        istop  = opts.domain[1],
        ostart = opts.range[0],
        ostop  = opts.range[1];

    return function scale(value) {
        return ostart + (ostop - ostart) * ((value - istart) / (istop - istart));
    };
};

function convertRange(value, r1, r2) {
    return ( value - r1[ 0 ] ) * ( r2[ 1 ] - r2[ 0 ] ) / ( r1[ 1 ] - r1[ 0 ] ) + r2[ 0 ];
}

function precisionRound(number, precision) {
    var factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
}

function getRandom(min, max) {
    return Math.random() * (max - min) + min;
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function popupwindow(url, title, w, h) {
    var x = window.outerWidth / 2 + window.screenX - (w / 2),
        y = window.outerHeight / 2 + window.screenY - (h / 2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + w + ', height=' + h + ', top=' + y + ', left=' + x);
}

function parseQueryString(queryString) {
    var params = {}, queries, temp, i, l;

    if (!queryString) {
        return params;
    }

    // Split into key/value pairs
    queries = queryString.split("&");

    // Convert the array of strings into an object
    for ( i = 0, l = queries.length; i < l; i++ ) {
        temp = queries[i].split('=');
        params[temp[0]] = temp[1];
    }

    return params;
};

function tweenPromise(tween) {
    return new Promise(function(resolve) {
        tween.eventCallback('onComplete', function() {
            resolve(true);
        });
    });
}

export { debounce, throttle, scale, convertRange, precisionRound, getRandom, getRandomInt, popupwindow, parseQueryString, tweenPromise };
