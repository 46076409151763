/* global jQuery */
import "../sass/main.scss";
import 'lazysizes';
import jQuery from "jquery";
import imagesLoaded from "imagesloaded";
import Barba from "barba.js";
import Flickity from "flickity";
import jQueryBridget from "jquery-bridget";
import Packery from "packery";
import initStickySidebar from "./sticky-sidebar";
import { debounce, throttle } from "./util.js";
import Typewriter from 'typewriter-effect/dist/core';
import {TimelineLite, TweenLite, TweenMax, Power1, Power4, Expo, gsap} from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
window.$ = window.jQuery = jQuery;

jQueryBridget( 'flickity', Flickity, $ );

gsap.registerPlugin(ScrollToPlugin)


var CA = CA || {},
    _hsq = (window._hsq = window._hsq || []);

CA.mq = {
    sm: "(min-width: 576px)",
    md: "(min-width: 768px)",
    lg: "(min-width: 1024px)",
};

CA.body_class = "";
CA.initial_load = true;
CA.to_animate = [];

CA.ajax_transition_active = false;
CA.init_ajax = function () {
    var linkClicked = true;

    Barba.Pjax.Dom.wrapperId = "main";
    Barba.Pjax.Dom.containerClass = "page";

    // Disable links while transition is in progress
    $("a").on("click", function (e) {
        if (CA.ajax_transition_active) {
            e.stopPropagation();
            e.preventDefault();
        }
    });

    var SubnavTransition = Barba.BaseTransition.extend({
        start: function () {
            Promise.all([this.newContainerLoading, this.fadeOut()]).then(this.fadeIn.bind(this));
        },
        fadeOut: function () {
            var tl = new TimelineLite();

            TweenMax.to(window, 1, {
                scrollTo: {
                    y: 0,
                    autoKill: false,
                },
                ease: Power4.easeInOut,
            });

            tl.to(
                "section:not(.hero)",
                0.5,
                {
                    opacity: 0,
                    y: "-40",
                    ease: Expo.easeIn,
                },
                0
            );

            return tl;
        },
        fadeIn: function () {

            var _this = this,
                tl = new TimelineLite();

            tl.set(_this.oldContainer, { display: "none" });
            tl.set(_this.newContainer, { visibility: "visible" });

            tl.fromTo(
                $(_this.newContainer).find("section:not(.hero)"),
                0.5,
                {
                    opacity: 0,
                    y: "40",
                },
                {
                    opacity: 1,
                    y: "0",
                    ease: Expo.easeOut,
                },
                0
            );

            tl.add(function () {
                $("body").attr("class", CA.body_class);
            }, 0.5);

            tl.eventCallback("onComplete", function () {
                _this.done();
            });
        },
    });

    var OverlayTransition = Barba.BaseTransition.extend({
        start: function () {
            Promise.all([this.newContainerLoading, this.fadeOut()]).then(this.fadeIn.bind(this));
        },
        fadeOut: function () {
            var tl = new TimelineLite();

            tl.to(
                "header",
                0.5,
                {
                    opacity: 0
                },
                0
            );

            tl.fromTo(
                ".overlay",
                1,
                {
                    y: "100%",
                },
                {
                    y: "0%",
                    ease: Expo.easeInOut,
                },
                0
            );

            tl.fromTo(
                ".overlay img",
                1,
                {
                    rotationX: 90,
                    z: 100,
                },
                {
                    rotationX: 0,
                    z: 0,
                    ease: Power1.easeInOut,
                },
                0
            );

            tl.to(
                this.oldContainer,
                1,
                {
                    opacity: 0,
                    y: -100,
                    ease: Expo.easeInOut,
                },
                0
            );

            return tl;
        },
        fadeIn: function () {
            var _this = this,
                tl = new TimelineLite();

            $(window).scrollTop(0);

            tl.set(_this.oldContainer, { display: "none" });
            tl.set(_this.newContainer, {
                visibility: "visible",
                opacity: 0,
                y: 100,
                scale: 1.1,
            });

            tl.to(
                "header",
                0.5,
                {
                    opacity: 1,
                },
                0.5
            );

            tl.fromTo(
                ".overlay",
                1,
                {
                    y: "0%",
                },
                {
                    y: "-100%",
                    ease: Expo.easeInOut,
                },
                0
            );

            tl.to(
                _this.newContainer,
                1,
                {
                    opacity: 1,
                    y: 0,
                    scale: 1,
                    ease: Expo.easeInOut,
                },
                0
            );

            tl.add(function () {
                $("body").attr("class", CA.body_class);
            }, 0.5);

            tl.eventCallback("onComplete", function () {
                $(".page").removeAttr("style");
                _this.done();
            });
        },
    });

    var QuickTransition = Barba.BaseTransition.extend({
        start: function () {
            Promise.all([this.newContainerLoading, this.fadeOut()]).then(this.fadeIn.bind(this));
        },
        fadeOut: function () {
            var tl = new TimelineLite();

            tl.to(
                this.oldContainer,
                0.5,
                {
                    opacity: 0,
                    y: -100,
                    ease: Expo.easeIn,
                },
                0
            );

            return tl;
        },
        fadeIn: function () {
            var _this = this,
                tl = new TimelineLite();

            $(window).scrollTop(0);

            tl.set(_this.oldContainer, { display: "none" });
            tl.set(_this.newContainer, {
                visibility: "visible",
                opacity: 0,
                y: 100,
                scale: 1.1,
            });

            tl.to(
                _this.newContainer,
                1,
                {
                    opacity: 1,
                    y: 0,
                    scale: 1,
                    ease: Expo.easeOut,
                },
                0
            );

            tl.add(function () {
                $("body").attr("class", CA.body_class);
            }, 0.25);

            tl.eventCallback("onComplete", function () {
                $(".page").removeAttr("style");
                _this.done();
            });
        },
    });

    var HideShowTransition = Barba.BaseTransition.extend({
        start: function () {
            Promise.all([this.newContainerLoading, this.fadeOut()]).then(this.fadeIn.bind(this));
        },
        fadeOut: function () {
            var tl = new TimelineLite();

            tl.to(
                this.oldContainer,
                0.1,
                {
                    opacity: 0,
                },
                0
            );

            return tl;
        },
        fadeIn: function () {
            var _this = this,
                tl = new TimelineLite();

            tl.set(_this.oldContainer, { display: "none" });
            tl.set(_this.newContainer, {
                visibility: "visible",
                opacity: 0,
            });

            tl.to(
                _this.newContainer,
                0.1,
                {
                    opacity: 1
                },
                0
            );

            tl.add(function () {
                $("body").attr("class", CA.body_class);
            }, 0.1);

            tl.eventCallback("onComplete", function () {
                $(".page").removeAttr("style");
                _this.done();
            });
        },
    });

    Barba.Pjax.getTransition = function () {
        if (!linkClicked) {
            return HideShowTransition;
        }

        // If the linkClicked is from the FAQ menu
        if ($(linkClicked).closest("nav.subnav").length) {
            return SubnavTransition;
        }

        if ($(linkClicked).closest("nav.filterbar").length) {
            return SubnavTransition;
        }

        if ($(linkClicked).closest(".filterbar-mobile-menu").length) {
            return SubnavTransition;
        }

        return QuickTransition;
    };

    Barba.Dispatcher.on("linkClicked", function (el) {
        linkClicked = el;
    });

    Barba.Dispatcher.on("initStateChange", function (args) {
        CA.init_active_nav();
        CA.ajax_transition_active = true;

        window.setTimeout(function () {
            $("html").removeClass("menu-active");
        }, 500);

        // Track pageview in Google Analytics
        if (!CA.initial_load && typeof ga === "function") {
            ga("send", "pageview", location.pathname);
        }

        // Track pageview in HubSpot
        if (!CA.initial_load) {
            _hsq.push(["trackPageView"]);
        }

        CA.initial_load = false;
    });

    Barba.Dispatcher.on("newPageReady", function (currentStatus, oldStatus, container, newPageRawHTML) {
        var regexp = /\<body.*\sclass=["'](.+?)["'].*\>/gi,
            match = regexp.exec(newPageRawHTML);

        CA.body_class = match && match[1] ? match[1] : "";
    });

    Barba.Dispatcher.on("transitionCompleted", function () {
        CA.ajax_transition_active = false;
        linkClicked = false;
        CA.init_on_load();
        $("body").removeClass("prevent-transitions");
    });

    Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;
    Barba.Pjax.preventCheck = function (evt, element) {
        if (!Barba.Pjax.originalPreventCheck(evt, element)) {
            return false;
        }

        if ($(element).closest(".yii-debug-toolbar").length) {
            return false;
        }

        return true;
    };

    Barba.Pjax.start();
    Barba.Prefetch.init();
};

CA.init_overlay = function () {
    var tl = new TimelineLite();

    tl.set(
        "body",
        {
            visibility: "visible",
        },
        0
    );

    tl.to(
        ".overlay img",
        0.25,
        {
            opacity: 1,
        },
        0.1
    );

    tl.fromTo(
        ".overlay",
        1,
        {
            y: "0%",
        },
        {
            y: "-100%",
            ease: Expo.easeInOut,
        },
        0.1
    );

    tl.fromTo(
        ".page",
        1,
        {
            opacity: 0,
            y: 100,
            scale: 1.1,
        },
        {
            opacity: 1,
            y: 0,
            scale: 1,
            ease: Expo.easeInOut,
            onComplete: function () {
                $(".page").removeAttr("style");
            }
        },
        0.1
    );
};

CA.init_sticky_header = function () {
    var html = $("html"),
        header = $("header"),
        win = $(window),
        scrollTop = 0,
        lastScrollTop = 0,
        direction = "down";

    win.scroll(function () {
        scrollTop = win.scrollTop();

        if (scrollTop > lastScrollTop && !html.hasClass("menu-active")) {
            header.removeClass("active");
            $(".sidebar-form").removeClass("offset-top");
            direction = "down";
        } else {
            header.addClass("active");
            $(".sidebar-form").addClass("offset-top");
            direction = "up";
        }

        if (direction == "down" && scrollTop > 100 && !header.hasClass("stuck")) {
            header.hide();
            header.addClass("stuck");

            window.setTimeout(function () {
                header.show();
            }, 100);
        }

        if (scrollTop == 0) {
            header.removeClass("stuck");
        }

        lastScrollTop = scrollTop;
    });
};

CA.init_header_menu = function () {
    var toggle = $("header .menu-toggle");

    toggle.on("click", function (e) {
        e.preventDefault();
        $("html").toggleClass("menu-active");
    });
};

CA.init_search_form = function () {
    var form = $("header form"),
        action = form.attr("action"),
        input = form.find("#searchInput");

    input.on("focus", function (e) {
        $("html").addClass("search-active");
    });

    input.on("blur", function (e) {
        if (!input.val()) {
            $("html").removeClass("search-active");
        }
    });

    form.on("submit", function (e) {
        e.preventDefault();

        if (input.val()) {
            $("html").removeClass("search-active");
            Barba.Pjax.goTo(action + "?" + form.serialize());

            window.setTimeout(function () {
                form[0].reset();
            }, 500);
        } else if (!$("html").hasClass("search-active")) {
            input.focus();
        }
    });

    $(window)
        .on(
            "resize",
            debounce(function () {
                input.attr("placeholder", "To search, type and press enter");
            }, 1000)
        )
        .resize();
};

CA.init_share_links = function () {
    $("body").on("click", ".share a", function (e) {
        e.preventDefault();
        var url = $(this).attr("href");
        popupwindow(url, "Share", 600, 460);
    });
};

CA.init_active_nav = function () {
    var currentUrl = window.location.href.replace(window.location.hash, "");

    // Remove the active class from other links
    $("header ul#menu a").removeClass("active");

    // Add the active class to header link with same href
    $('header ul#menu a[href="' + currentUrl + '"]').addClass("active");
};


CA.init_filterbar_menu = function () {
    var nav = $("nav.filterbar, .filterbar-mobile-menu"),
        menu = nav.find("ul").not("ul ul"),
        items = menu.find("li").not(".overflow, .search-wrap"),
        links = menu.find("a"),
        toggle = menu.find("button"),
        dropdown_link = menu.find(".dropdown-link"),
        dropdown_menu = menu.find(".dropdown-menu");

    if (!nav.length) {
        return;
    }

    if ($(".filterbar-mobile-menu") && !$(".filterbar-mobile-menu.in-the-right-spot").length) {
        $(".filterbar-mobile-menu").addClass("in-the-right-spot").insertAfter(".overlay");
    }

    toggle.on("click", function () {
        if ($(this).parent().hasClass("active")) {
            menu.removeClass("active");
            $(this).parent().removeClass("active");
        } else {
            $(".filterbar .active").removeClass("active");
            menu.toggleClass("active");
            $(this).parent().toggleClass("active");
        }
    });

    links.on("click", function () {
        menu.removeClass("active");
    });

    $(".mobile-menu-toggle-button").on("click", function () {
        $("html").addClass("overflow-mobile-stuck");
        gsap.fromTo(".filterbar-mobile-menu", {
            opacity: 0,
            display: "none"
        }, {
            opacity: 1, 
            display: "flex"
        });
    });
    
    $(".filterbar-mobile-menu__close").on("click", function () {
        $("html").removeClass("overflow-mobile-stuck");
        gsap.fromTo(".filterbar-mobile-menu", {
            opacity: 1,
            display: "flex"
        }, {
            opacity: 0, 
            display: "none"
        });
    });

    $(".filterbar-mobile-menu .dropdown li a").on("click", function() {
        $(".filterbar-mobile-menu__close").trigger("click");
    });
    
};

CA.init_subnav_menu = function () {
    var nav = $("nav.subnav"),
        menu = nav.find("ul").not("ul ul"),
        items = menu.find("li").not(".overflow, .search-wrap"),
        links = menu.find("a"),
        toggle = menu.find("button"),
        search_link = menu.find(".search-wrap"),
        overflow_link = nav.find(".overflow"),
        overflow_menu = nav.find(".overflow > ul");

    if (!nav.length) {
        return;
    }

    toggle.on("click", function () {
        menu.toggleClass("active");
    });

    links.on("click", function () {
        menu.removeClass("active");
    });

    overflow_link.click(function () {
        overflow_link.toggleClass("active");
    });

    $(window).resize(
        throttle(function () {
            nav.addClass("active");

            menu.prepend(items);

            if ($(window).width() < 992) {
                return;
            }

            var menu_width = menu.width(),
                width = overflow_link.width() + search_link.width(),
                i = 0,
                link;

            while (width < menu_width) {
                link = items.eq(i);
                width += link.width();
                i++;
            }

            items.slice(i).appendTo(overflow_menu);

            if (i >= items.length) {
                overflow_link.addClass("d-lg-none");
                overflow_link.removeClass("d-lg-block");
            } else {
                overflow_link.addClass("d-lg-block");
                overflow_link.removeClass("d-lg-none");
            }
        }, 100)
    );

    $(window).resize();
};

CA.init_category_filters = function () {
    var nav = $("nav.category-filters"),
        menu = nav.find("ul").not("ul ul"),
        items = menu.find("li").not(".overflow, .search-wrap"),
        links = items.find("a");

    if (!nav.length) {
        return;
    }

    links.on("click", function (e) {
        e.preventDefault();

        var link = $(this),
            category = link.data("category"),
            href = "/blog/partial/" + category,
            container = $("#category-partial"),
            newContent = "";

        var fadeOut = TweenMax.to(container, 0.5, {
            opacity: 0,
            y: 20,
            ease: Power1.easeIn,
        });

        var loadContent = $.get(href, function (data) {
            newContent = data;
        });

        Promise.all([loadContent, fadeOut]).then(function () {
            var newContainer = $(newContent),
                tl = new TimelineLite();

            if (newContainer.filter(".article-grid").length) {
                newContainer = newContainer.filter(".article-grid");
            } else {
                newContainer = newContainer.find(".article-grid");
            }

            container.replaceWith(newContainer);
            CA.init_scroll_observers();

            TweenMax.fromTo(
                newContainer,
                1,
                {
                    opacity: 0,
                    y: 20,
                },
                {
                    opacity: 1,
                    y: 0,
                    ease: Expo.easeOut,
                }
            );
        });
    });
};

CA.init_blog_pagination = function () {
    var body = $("body"),
        container = $("#category-page");

    if (!container.length) {
        return;
    }

    body.on("click", ".paginate", function (e) {
        e.preventDefault();

        var link = $(this);
        link.fadeTo(0.5, 0.5);

        $.get(this.href, function (data) {
            container.append(data);
            link.parent().remove();
            CA.init_scroll_observers();
        });
    });
};

CA.init_slider = function () {
    var container = $("section.slider"),
        slider = container.find(".slider-wrap"),
        links = slider.find("a");

    if (!slider.length) {
        return;
    }

    slider.flickity({
        freeScroll: false,
        contain: true,
        wrapAround: true,
        imagesLoaded: true,
        prevNextButtons: true,
        pageDots: true,
        arrowShape:
            "M6.406 51.669L53.09 98.384l-2.202 2.064-49.965-50 49.965-50 2.202 2.064L6.932 48.7h93.99v2.968H6.406z",
    });

    slider.on("change.flickity", function (event, index) {
        links.removeClass("video-active");
        links.find("iframe").remove();
    });

    links.each(function () {
        var link = $(this),
            video = urlParser.parse(this.href);

        if (!video) {
            return;
        }

        link.addClass("video");
        link.click(function (e) {
            e.preventDefault();
        });
    });

    slider.on("staticClick.flickity", function (event, pointer, cellElement, cellIndex) {
        var link = $(cellElement),
            video = urlParser.parse(cellElement.href),
            iframe,
            src;

        if (video.provider == "youtube") {
            src = "https://www.youtube.com/embed/" + video.id;
        } else if (video.provider == "vimeo") {
            src = "https://player.vimeo.com/video/" + video.id + "?byline=0&portrait=0&autoplay=1";
        } else {
            return;
        }

        iframe = $("<iframe>", {
            src: src,
            frameborder: 0,
            allowfullscreen: true,
        });

        link.append(iframe);
        link.addClass("video-active");
    });
};

CA.init_video = function () {
    var container = $("section.video"),
        video = container.find(".video");

    if (!video.length) {
        return;
    }

    video.on("click", function (e) {
        var link = $(this),
            video = urlParser.parse(this.href),
            iframe,
            src;

        e.preventDefault();

        if (video.provider == "youtube") {
            src = "https://www.youtube.com/embed/" + video.id;
        } else if (video.provider == "vimeo") {
            src = "https://player.vimeo.com/video/" + video.id + "?byline=0&portrait=0&autoplay=1";
        } else {
            return;
        }

        iframe = $("<iframe>", {
            src: src,
            frameborder: 0,
            allowfullscreen: true,
        });

        link.append(iframe);
        link.addClass("video-active");
    });
};

CA.init_scroll_animations = function () {
    window.setInterval(function () {
        $.each(CA.to_animate, function (i, el) {
            window.setTimeout(function () {
                $(el).addClass("active");
            }, 100 * i);
        });

        CA.to_animate = [];
    }, 100);
};

CA.update_animations = function () {
    $.each(CA.to_animate, function (i, el) {
        window.setTimeout(function () {
            $(el).addClass("active");
        }, i * 150);
    });

    CA.to_animate = [];
};

CA.init_scroll_observers = function () {
    var animationClasses = [
            ".fade-in-up",
            ".slide-in-left",
            ".slide-in-right",
            ".vertical-rule",
            ".sidebar ul",
            ".sidebar ol",
            ".sidebar dl",
        ],
        elements = document.querySelectorAll(animationClasses.join(", "));

    var observer = new IntersectionObserver(
        function (entries, self) {
            $.each(entries, function (i, entry) {
                if (entry.isIntersecting) {
                    CA.to_animate.push(entry.target);
                    self.unobserve(entry.target);
                }
            });
        },
        {
            threshold: 0,
        }
    );

    $.each(elements, function (i, el) {
        observer.observe(el);
    });
};

CA.init_button_animations = function () {
    $(".btn-outline-light, .btn-outline-dark").addClass("animate-outline").wrapInner("<span />");
};

CA.init_logo_ticker = function () {
    var container = $("section.logo-ticker"),
        rows = container.find(".logo-row");

    if (!rows.length) {
        return;
    }

    var observer = new IntersectionObserver(
        function (entries, self) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    window.setInterval(function () {
                        var active = rows.filter(".active"),
                            next = active.next().length ? active.next() : rows.eq(0),
                            tl = new TimelineLite();

                        tl.staggerTo(
                            active.find(".logo"),
                            0.75,
                            {
                                opacity: 0,
                                y: 20,
                                ease: Expo.easeIn,
                            },
                            0.1
                        );

                        tl.add(function () {
                            active.removeClass("active");
                            next.addClass("active");
                        });

                        tl.staggerFromTo(
                            next.find(".logo"),
                            0.75,
                            {
                                opacity: 0,
                                y: 20,
                            },
                            {
                                opacity: 1,
                                y: 0,
                                ease: Expo.easeOut,
                            },
                            0.1
                        );
                    }, 5000);

                    self.unobserve(entry.target);
                }
            });
        },
        {
            threshold: 0,
        }
    );

    observer.observe(container[0]);
};

CA.init_typewriter = function () {
    var containers = $("[data-type]");

    containers.each(function () {
        var container = $(this),
            strings = container.data("type").split(","),
            typewriter;

        typewriter = new Typewriter(container[0], {
            strings: strings,
            loop: true,
            autoStart: true,
        });

        typewriter.stop();

        window.setTimeout(function () {
            typewriter.start();
        }, 1000);
    });
};

CA.init_hubspot_forms = function () {
    var containers = $(".hbspt-form");

    // Make sure the Hubspot JS is loaded
    if (typeof hbspt == "undefined") {
        return;
    }

    if (!containers.length) {
        return;
    }

    containers.each(function (i, v) {
        var container = $(this),
            id = "hbspt-form-" + i;

        // Add a unique ID for the "target" param below
        container.attr("id", id);

        hbspt.forms.create({
            portalId: container.data("portal"),
            formId: container.data("form"),
            target: "#" + id,
            onFormSubmitted: function (form) {
                // If we are in a .call-to-action block, update the confirmation message
                // if (!form.closest(".call-to-action").length) {
                //     return;
                // }

                // var message = container.find(".submitted-message").text(),
                //     body = form.closest(".call-to-action").find(".form-body");

                // Add a line break on desktop
                // message = message.replace("!", '!<br class="d-none d-md-block" />');

                // // Replace the body with the confirmation message
                // TweenMax.set(body, { opacity: 0, y: 20 });
                // body.html($("<h2 />", { html: message }));
                // TweenMax.to(body, 2, { opacity: 1, y: 0, ease: Expo.easeOut });

                // // Remove the form container
                // container.remove();
            },
        });
    });
};

CA.init_nested_links = function () {
    $("body").on("mouseenter focus", "[data-href]", function () {
        var link = $(this).closest("a"),
            originalHref = link.attr("href"),
            newHref = $(this).data("href");

        link.data("originalHref", originalHref);
        link.attr("href", newHref);
    });

    $("body").on("mouseout blur", "[data-href]", function () {
        var link = $(this).closest("a"),
            originalHref = link.data("originalHref");

        link.attr("href", originalHref);
    });

    $("body").on("keyup", "[data-href]", function (e) {
        var link = $(this).closest("a");

        if (e.which == 13) {
            link[0].click();
        }
    });
};

CA.init_animated_underlines = function () {
    $("body").on("mouseenter", "a, button", function () {
        var link = $(this),
            underlines = link.find(".underline"),
            color = getComputedStyle(link[0]).color,
            obj = { color: "rgba(0,0,0,0)" };

        if (!underlines.length) {
            return;
        }

        // Special case for header links
        if (link.closest("header").length && $("body").hasClass("header-light")) {
            color = "#E1B966";
        }

        var tween = TweenMax.to(obj, 0.25, {
            colorProps: {
                color: color,
            },
            onUpdate: function () {
                var bg = "linear-gradient(to right, " + obj.color + ", " + obj.color + ")";
                TweenLite.set(underlines, {
                    backgroundImage: bg,
                });
            },
        });
    });

    $("body").on("mouseleave", "a, button", function () {
        var link = $(this),
            underlines = link.find(".underline"),
            color = getComputedStyle(link[0]).color,
            obj = { color: color };

        if (!underlines.length) {
            return;
        }

        // Special case for header links
        if (link.closest("header a.active").length && $("body").hasClass("header-light")) {
            color = "#FFFFFF";
        } else if (link.closest("header a.active").length) {
            color = "#000000";
        } else {
            color = "rgba(0,0,0,0)";
        }

        var tween = TweenMax.to(obj, 0.25, {
            colorProps: {
                color: color,
            },
            onUpdate: function () {
                var bg = "linear-gradient(to right, " + obj.color + ", " + obj.color + ")";
                TweenLite.set(underlines, {
                    backgroundImage: bg,
                });
            },
            onComplete: function () {
                underlines.css("backgroundImage", "");
            },
        });
    });
};

CA.init_animated_heading_underlines = function () {
    $("body").on("mouseenter", "h1 a, h2 a", function () {
        var link = $(this),
            color = "#333333",
            obj = { color: "#E4EAED" };

        var tween = TweenMax.to(obj, 0.25, {
            colorProps: {
                color: color,
            },
            onUpdate: function () {
                var bg = "linear-gradient(to right, " + obj.color + ", " + obj.color + ")";
                TweenLite.set(link, {
                    backgroundImage: bg,
                });
            },
        });
    });

    $("body").on("mouseleave", "h1 a, h2 a", function () {
        var link = $(this),
            color = "#E4EAED",
            obj = { color: "#333333" };

        var tween = TweenMax.to(obj, 0.25, {
            colorProps: {
                color: color,
            },
            onUpdate: function () {
                var bg = "linear-gradient(to right, " + obj.color + ", " + obj.color + ")";
                TweenLite.set(link, { backgroundImage: bg });
            },
            onComplete: function () {
                TweenLite.set(link, { backgroundImage: "linear-gradient(to right, #E4EAED, #E4EAED)" });
            },
        });
    });
};

CA.doStars = function () {

    const canvas = document.getElementById('skyCanvas');

    if (!canvas) {
        return false;
    }

    // Create a div that will cover the canvas
    let coverDiv = document.createElement('div');
    coverDiv.style.position = 'absolute';
    coverDiv.style.top = '0';
    coverDiv.style.left = '0';
    coverDiv.style.width = '100%';
    coverDiv.style.height = '100%';
    coverDiv.style.transition = 'background 5s, opacity 1s'; // Transition time for the background color and opacity
    coverDiv.style.background = '#0e0e10'; // Initial color
    coverDiv.style.opacity = '0'; // Initially fully transparent

    let stars = [];
    let currentAnimation = null;
    let isResized = false;
    canvas.parentElement.appendChild(coverDiv);

    const ctx = canvas.getContext('2d');

    class Star {
        constructor(x, y, color) {
        this.x = x;
        this.y = y;
        this.size = Math.random() * 0.5;
        this.color = color;
        this.angle = Math.atan2(y - canvas.height / 2, x - canvas.width / 2);
        this.distanceFromCenter = Math.hypot(x - canvas.width / 2, y - canvas.height / 2);
        this.speed = 0.01 * Math.random();
        
        this.opacity = Math.random();
        this.opacityDirection = Math.random() > 0.5 ? 1 : -1;
        this.maxShadowBlur = this.size * 2;
        this.shadowBlur = this.maxShadowBlur;
        this.shadowBlurDecay = Math.random() * 0.05 + 0.01; // Random decay rate between 0.01 and 0.06
        }
    
        draw(ctx) {
        let [r, g, b] = this.color.slice(1).match(/.{2}/g).map(c => parseInt(c, 16));
        ctx.shadowBlur = this.shadowBlur;
        ctx.shadowColor = `rgba(${r}, ${g}, ${b}, 0.8)`;
        ctx.fillStyle = `rgba(${r}, ${g}, ${b}, ${this.opacity})`;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
    
        this.angle += this.speed;
        this.x = canvas.width / 2 + Math.cos(this.angle) * this.distanceFromCenter;
        this.y = canvas.height / 2 + Math.sin(this.angle) * this.distanceFromCenter;
    
        // Decay the shadow blur
        this.shadowBlur -= this.shadowBlurDecay;
        if (this.shadowBlur < 0) {
            this.shadowBlur = 0;
        }
    
        // Update opacity for blinking
        this.opacity += 0.01 * this.opacityDirection;
        if (this.opacity > 1) {
            this.opacity = 1;
            this.opacityDirection = -1;
        } else if (this.opacity < 0) {
            this.opacity = 0;
            this.opacityDirection = 1;
        }
        }
    }
    
    
    function go() {
            isResized = false;
            
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            canvas.width = $(".call-to-action").outerWidth();
            canvas.height = $(".call-to-action").outerHeight();;
    
            ctx.fillStyle = '#0e0e10';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            
            const starColors = ['#cfab57', '#acadb1'];
            
            stars = [];
            for (let i = 0; i < 500; i++) {
                let x = Math.random() * canvas.width;
                let y = Math.random() * canvas.height;
                let color = starColors[Math.floor(Math.random() * starColors.length)];
                stars.push(new Star(x, y, color));
            }
            
            animate();
    
        }
        
    function animate() {
        for (let star of stars) {
            star.draw(ctx);
        }
        if (!isResized) {
            currentAnimation = requestAnimationFrame(animate);
        }
    }
        
    function clearCanvas() {
        coverDiv.style.opacity = '1'; 
        setTimeout(() => {
            coverDiv.style.opacity = '0'; 
            isResized = true;
            window.cancelAnimationFrame(currentAnimation);
            go();
        }, 1000);
    }
    
    var int = setInterval(clearCanvas, 30000);
    
    go();

    var lastWidth = window.innerWidth;
    
    window.addEventListener('resize', () => {
        if (window.innerWidth === lastWidth) {
            return;
        }
        lastWidth = window.innerWidth;
        isResized = true;
        window.cancelAnimationFrame(currentAnimation);
        window.clearInterval(int);
        int = setInterval(clearCanvas, 30000);
        go();
    });
}

CA.initAds = function() {

    if ($(".advertisement").length === 0) {
        return;
    }

    function sizeiframes() {
        $(".advertisement iframe").each(function () {
            var $that = $(this);
            var $parent = $that.parent();
            var $img = $parent.find("img");
            var width = $img.width();
            var height = $img.height();
            $that.width(width);
            $that.height(height);
        });
    }

    sizeiframes();

    $(window).on("resize", function() {
        sizeiframes();
    });

    $(".advertisement").each(function() {
        var $that = $(this);
        var $iframe = $that.find("iframe");
        var touched = $(this).data("touched");

        if (touched) {
            return;
        }

        $(this).data("touched", true);

        if ($(this).find("img").length && $iframe.length) {
            $iframe.remove();
        }

        $(this).find(".advertisement__play").on("click", function() {
            $(this).replaceWith("<button class='advertisement__replay'>Replay</button>");
            $that.find("img").hide();
            $that.prepend($iframe);
            $that.find(".advertisement__replay").on("click", function() {
                $iframe.remove();
                $that.prepend($iframe);
            });
        });
    });

    const grid = document.getElementsByClassName('ad-campaign-grid');

    Array.from(grid).forEach(function(item) {
        imagesLoaded( item, function() {
            const pkry = new Packery(item, {
                itemSelector: '.advertisement',
            });
        });
    });
};

CA.do_custom_select = function() {
    $("select:not(.gt_selector)").each(function() {
        var $select = $(this);
        $select.wrap('<div class="custom-select"></div>');
    });
};

CA.init_on_load = function () {
    CA.init_subnav_menu();
    CA.init_filterbar_menu();
    CA.init_category_filters();
    CA.init_blog_pagination();
    CA.init_slider();
    CA.init_video();
    CA.init_scroll_observers();
    CA.init_button_animations();
    CA.init_logo_ticker();
    CA.init_typewriter();
    CA.init_hubspot_forms();
    CA.initAds();
    initStickySidebar();
    window.setTimeout(function() {
        CA.doStars();
    }, 2000);
};

$(document).ready(function () {
    CA.init_ajax();
    CA.init_overlay();
    CA.init_sticky_header();
    CA.init_header_menu();
    CA.init_search_form();
    CA.init_share_links();
    CA.init_scroll_animations();
    CA.init_nested_links();
    // CA.init_animated_underlines();
    // CA.init_animated_heading_underlines();
    CA.do_custom_select();
    // window.addEventListener('message', event => {
    //     if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
    //         console.log(event);
    //         var elementId = event.data.id;
    //         $(".hbspt-form[data-form='" + elementId + "']").each(function () {
    //             $selects = $(this).find("select");
    //             if ($(this).hasClass("custom-selected")) {
    //                 return;
    //             }
    //             $(this).addClass("custom-selected");
    //             customSelect($selects);
    //         });
    //     }
    //  });
});

$(window).on("load", function() {
    CA.initAds();
});